<template>
    <div class="login">
        <div class="login_title" v-show="loginState">{{$t('mailboxLogin')}}</div>
        <div class="login_title" v-show="!loginState">{{$t('phoneLogin')}}</div>
        <div class="login_box" v-show="!loginState">
            <div class="login_inp inp">
                <span>+86</span>
                |
                <input type="text" v-model="userPhone" maxlength="11" :placeholder="$t('changePhoneText')">
            </div>
            <div class="login_inp login_inp_flex">
                <input type="text" v-model="passWord" maxlength="6" :placeholder="$t('changeCodeText')">
                <div v-show="!codeState" class="get_code_text" @click="sendCode">{{$t('getVerificationCode')}}</div>
                <div v-show="codeState" class="get_code_text" @click="sendCode">{{codeNum + 's'}}</div>
            </div>
        </div>
        <div class="login_box" v-show="loginState">
            <div class="login_inp inp">
                <input type="text" v-model="userPhone" :placeholder="$t('mailboxAccount')">
            </div>
            <div class="login_inp login_inp_flex">
                <input type="text" v-model="passWord" maxlength="6" :placeholder="$t('mailboxCode')">
                <div v-show="!codeState" class="get_code_text" @click="sendCode">{{$t('getVerificationCode')}}</div>
                <div v-show="codeState" class="get_code_text" @click="sendCode">{{codeNum + 's'}}</div>
            </div>
        </div>
        <div class="login_but_box" @click="getVerification">
            <div class="login_but">{{$t("registerOrLogin")}}
            </div>
        </div>
        <div class="dimension">{{$t('dimension')}}</div>
        <!-- 邮箱登录 -->
        <div class="login_change_login" v-show="!loginState">
            <img src="@/assets/img/mailbox.png" alt="">
            <div class="mailbox_but" @click="changeLoginWay">{{$t("mailboxLogin")}}</div>
        </div>
        <!-- 手机号登录 -->
        <div class="login_change_login" v-show="loginState">
            <img src="@/assets/img/phone.png" alt="">
            <div class="mailbox_but" @click="changeLoginWay">{{$t("phoneLogin")}}</div>
        </div>
        <div class="login_agreement">
            <van-checkbox v-model="checked" shape="square" />
            <p style="margin: 0 6px;">{{$t('readAndAgree')}}</p>
            <p style="font-weight: 600" @click="toAgreement">{{$t('UserAgreement')}}</p>
            <p>{{$t('and')}}</p>
            <p style="font-weight: 600" @click="toPrivacyPolicy">{{$t('privacyTerms')}}</p>
        </div>
    </div>
</template>
<script>
    import { Toast } from "vant"
    import throttle from "@/utils/common.js"
    import "vant/lib/index.css"
    // var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var emailRegex = /@/;
    var phoneCodeVerification = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    export default {
        data() {
            return {
                // 定时器
                codeTimer: null,
                // 秒
                codeNum: 60,
                // 发送验证码状态
                codeState: false,
                loginState: false,
                // 手机号
                userPhone: "",
                // 手机验证码
                passWord: "",
                checked: true
            }
        },
        mounted() {
            if (sessionStorage.getItem("localeDate") && JSON.parse(sessionStorage.getItem("localeDate")).locale != 'zh') {
                this.loginState = true;
            }
        },
        methods: {
            // 去隐私政策页
            toPrivacyPolicy() {
                if (this.$i18n.locale == 'zh') {
                    this.$router.push({
                        path: "/user_policy?path=https://www.ustyle.cn/privacyPolicy/ww-index.html"
                    })
                } else {
                    console.log(this.$i18n.locale)
                    this.$router.push({
                        path: "/user_policy?path=https://www.ustyle.cn/privacyPolicy/ww-index-en.html"
                    })
                }
            },
            // 去用户协议页
            toAgreement() {
                if (this.$i18n.locale == 'zh') {
                    this.$router.push({
                        path: "/user_policy?path=https://www.ustyle.cn/privacyAgreement/ww-index.html"
                    })
                } else {
                    console.log(this.$i18n.locale)
                    this.$router.push({
                        path: "/user_policy?path=https://www.ustyle.cn/privacyAgreement/ww-index-en.html"
                    })
                }
            },
            // 点击登录
            getVerification: throttle(function () {
                if (this.userPhone == '') {
                    return
                }
                if (this.passWord.length < 6) {
                    Toast.fail(this.$t('versionErr'))
                    return
                }
                if (!this.loginState) {
                    if (phoneCodeVerification.test(this.userPhone) == false) {
                        Toast(this.$t('errNumber'))
                        return
                    }
                } else {
                    if (emailRegex.test(this.userPhone) == false) {
                        Toast(this.$t('emailError'))
                        return
                    }
                }
                console.log(this.checked)
                if (!this.checked) {
                    Toast(this.$t('showText'))
                    return
                }
                let data = {
                    language: this.$i18n.locale,
                    type: !this.loginState ? 0 : 1,
                    username: this.loginState == false ? this.userPhone : '',
                    mail: this.loginState == true ? this.userPhone : '',
                    verifyCode: this.passWord
                }
                console.log(data)
                this.$http.post("/login", data).then(res => {
                    console.log(res)
                    if (res.resultCode === 200) {
                        let user_info = res.data;
                        if (!this.loginState) {
                            user_info.type = 0;
                        } else {
                            user_info.type = 1;
                        }
                        console.log(user_info)
                        localStorage.setItem("h5_user_info", JSON.stringify(user_info))
                        clearInterval(this.codeTimer)
                        this.codeTimer = null;
                        this.$router.go(-1)
                    } else {
                        Toast.fail('error: ' + res.resultCode)
                    }
                })
            }, 300),
            // 切换邮箱登录
            changeLoginWay() {
                this.loginState = !this.loginState;
                this.userPhone = "";
                this.passWord = "";
            },
            // 发送验证码
            sendCode() {
                if (this.codeState == true) return
                // 邮箱登录
                if (this.loginState) {
                    if (emailRegex.test(this.userPhone) == false) {
                        if (phoneCodeVerification.test(this.userPhone) == false) {
                            Toast.fail(this.$t('emailFormat'))
                            return
                        }
                    }
                } else {// 手机号登录
                    // 如果手机号和验证码为空
                    if (this.userPhone == "") {
                        Toast.fail(this.$t('numNoNull'));
                        return;
                    }
                    // 验证手机格式是否正确
                    if (phoneCodeVerification.test(this.userPhone) == false) {
                        Toast.fail(this.$t('numberFormat'));
                        return;
                    }
                }
                let data = {
                    language: this.$i18n.locale,
                    type: !this.loginState ? 0 : 1,
                    // 如果为手机号登录
                    mobile: this.loginState == false ? this.userPhone : '',
                    // 如果为邮箱登录
                    mail: this.loginState == true ? this.userPhone : '',
                }
                console.log(data)
                this.codeState = true;
                this.codeTimer = setInterval(() => {
                    this.codeNum--;
                    console.log(this.codeNum)
                    if (this.codeNum === 0) {
                        this.codeState = false;
                        this.codeNum = 60;
                        clearInterval(this.codeTimer)
                        this.codeTimer = null;
                    }
                }, 1000);
                this.$http.post("/send", data).then(res => {
                    if (res.resultCode == 200) {
                        Toast(this.$t('sendSuccess'))
                    } else if (res.resultCode !== 200 && res.resultCode !== 501) {
                        Toast.fail(this.$t('sendError'))
                    }
                })
            }
        }
    }
</script>
<style scoped>
    input {
        border: none;
        background-color: #ffffff;
        /* width: 100%; */
        height: 100%;
        outline: none;
        font-size: 15px;
    }

    .login {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        background-image: url('../assets/img/home_back.png');
        background-repeat: no-repeat;
        /* 让背景图基于容器大小伸缩 */
        background-size: cover;
        background-attachment: fixed;
        background-position-y: 100%;
    }

    .login_title {
        width: 100%;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        margin: 72px 0 28px 0;
    }

    .login_box {
        width: 314px;
        height: max-content;
        /* margin-top: 127px; */
        /* position: absolute;
        top: 127px;
        left: 50%;
        margin-left: -157px; */
        background-color: #ffffff;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 4px rgba(57, 152, 211, 0.25);
    }

    .login_inp {
        display: inline-block;
        width: 271px;
        height: 57px;
        font-size: 17px;
    }

    .login_inp>span {
        color: #000000;
        font-size: 14px;
        font-weight: 700;
    }

    .login_inp>input {
        color: #000000;
        font-size: 14px;
        font-weight: 700;
    }

    .login_inp input::placeholder {
        color: #999999;
        font-size: 14px;
        font-weight: 700;
    }

    .login_inp_flex {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .inp {
        border-bottom: 1px solid #999999;
        z-index: 99;
    }

    .get_code_text {
        width: max-content;
        font-size: 14px;
        color: rgba(57, 152, 211, 1);
    }

    .login_change_login {
        width: 312px;
        height: 24px;
        /* position: absolute;
        bottom: 178px;
        left: 50%;
        margin-left: -156px; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 140px;
    }

    .login_change_login div {
        color: #3998D3;
        font-size: 14px;
        font-weight: 600;
    }

    .login_change_login img {
        width: 20px;
        height: 20px;
        margin: 0 5px;
        display: inline-block
    }

    .login_but_box {
        width: 312px;
        height: max-content;
        /* position: absolute;
        top: calc(127px + 57px + 57px + 20px);
        left: 50%;
        margin-left: -156px; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .dimension {
        width: 312px;
        text-align: center;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 700;
        color: rgba(153, 153, 153, 1);
        padding: 10px 0;
    }

    .login_but {
        width: 312px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-radius: 2px;
        background: rgba(57, 152, 211, 1);
        font-size: 16px;
        color: #ffffff;
        box-shadow: 0 2px #00000004;
        cursor: pointer;
        transition: .3s;
    }

    .login_but_actived {
        background: rgba(57, 152, 211, 1) !important;
    }

    .login_but:hover {
        border-color: currentColor;
    }

    .login_but:active::after {
        box-shadow: none;
        opacity: 0.4;
        transition: 0s;
    }

    .mailbox_but {
        /* width: 100%; */
        display: inline-block;
        text-align: center;
        margin: 20px 0;
        font-size: 16px;
        color: rgba(153, 153, 153, 1);
        text-align: center;
    }

    .login_agreement {
        width: 312px;
        font-size: 14px;
        word-wrap: break-word;
        margin-top: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        color: #ffffff;
    }
</style>